/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import slug from "slug"
import * as icons from "../utils/icons"

function concatArticles(node) {
  return [
    ...(Array.isArray(node.articles) ? node.articles : []),
    ...(Array.isArray(node.sections)
      ? node.sections.flatMap((section) =>
        Array.isArray(section.articles) ? section.articles : []
      )
      : []),
  ]
}

function Card(props) {
  return (
    <Link to={props.to} className="no-underline hover:no-underline">
      <div className="bg-white border border-1 border-gray-300 px-5 py-6 z-10 no-underline overflow-hidden w-full block outline-none shadow transition-all duration-150 hover:bg-gray-50"
        sx={{
          borderBottomColor: props.hasSuccessor
            ? "transparent"
            : "rgba(136,149,162,0.2)",
          borderTopLeftRadius: props.hasPredecessor ? 0 : 3,
          borderTopRightRadius: props.hasPredecessor ? 0 : 3,
          borderBottomLeftRadius: props.hasSuccessor ? 0 : 3,
          borderBottomRightRadius: props.hasSuccessor ? 0 : 3
        }}
      >
        <h3 className="text-blue-700 hover:text-blue-800">
          {props.title}
        </h3>
        <div className="text-sm text-gray-500 mt-2">
          {props.description}
        </div>
      </div>
    </Link>
  )
}

class CollectionTemplate extends React.Component {
  render() {
    const siteTitle = this.props.data.site.siteMetadata.title
    const collection = this.props.data.collection
    const articles = concatArticles(collection)

    let icon =
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.22766 9C8.77678 7.83481 10.2584 7 12.0001 7C14.2092 7 16.0001 8.34315 16.0001 10C16.0001 11.3994 14.7224 12.5751 12.9943 12.9066C12.4519 13.0106 12.0001 13.4477 12.0001 14M12 17H12.01M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z" stroke="currentColor" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    if (collection.icon === "money") {
      icon =
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1} d="M17 9V7a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2m2 4h10a2 2 0 002-2v-6a2 2 0 00-2-2H9a2 2 0 00-2 2v6a2 2 0 002 2zm7-5a2 2 0 11-4 0 2 2 0 014 0z" />
        </svg>
    }
    else if (collection.icon === "template") {
      icon =
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1} d="M4 5a1 1 0 011-1h14a1 1 0 011 1v2a1 1 0 01-1 1H5a1 1 0 01-1-1V5zM4 13a1 1 0 011-1h6a1 1 0 011 1v6a1 1 0 01-1 1H5a1 1 0 01-1-1v-6zM16 13a1 1 0 011-1h2a1 1 0 011 1v6a1 1 0 01-1 1h-2a1 1 0 01-1-1v-6z" />
        </svg>
    }

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title={collection.title} description={collection.description} />
        <p className="mb-2">
          <Link to="/" className="text-xs text-gray-700 hover:text-blue-800">
            {this.props.data.site.siteMetadata.texts.allCollectionsText}
          </Link>
          {" "}
          <span className="text-xs text-gray-500">
            &rsaquo;
          </span>
          {" "}
          <span className="text-xs text-gray-500">
            {collection.title}
          </span>
        </p>
        <article className="rounded-md px-6 py-6 mb-8 bg-gray-200">
          <div className="flex pt-2">
            <div className="hidden sm:flex flex-1 -mt-1">
              <div className="w-20 p-4 text-white bg-gray-400 rounded-lg">
                {icon}
              </div>
            </div>
            <div className="pl-1" sx={{ flex: 6 }}>
              <header>
                <h2 className="text-gray-800">
                  {collection.title}
                </h2>
              </header>
              <p className="text-gray-500 text-sm mt-2 mb-1">
                {collection.description}
              </p>
              <small className="text-xs text-gray-500">
                {articles.length}{" "}
                {(() => {
                  switch (articles.length) {
                    case 0:
                      return this.props.data.site.siteMetadata.texts
                        .articlesInCollectionZeroText
                    case 1:
                      return this.props.data.site.siteMetadata.texts
                        .articlesInCollectionOneText
                    case 2:
                      return this.props.data.site.siteMetadata.texts
                        .articlesInCollectionTwoText
                    default:
                      return this.props.data.site.siteMetadata.texts
                        .articlesInCollectionMultipleText
                  }
                })()}
              </small>
            </div>
          </div>
          <ul className="list-none mt-8">
            {(Array.isArray(collection.articles)
              ? collection.articles
              : []
            ).map((articleNode, index) => {
              // This happens when a collection points to an article file which
              // does not exist
              if (
                !articleNode ||
                !articleNode.file ||
                !articleNode.file.childMarkdownRemark
              ) {
                return null
              }

              const article = articleNode.file.childMarkdownRemark
              if (!article) return null
              return (
                <li key={article.fields.slug} className="mb-0">
                  <Card
                    to={article.fields.slug}
                    title={article.frontmatter.title}
                    description={article.frontmatter.description}
                    hasPredecessor={index > 0}
                    hasSuccessor={index < collection.articles.length - 1}
                  />
                </li>
              )
            })}
          </ul>
          {Array.isArray(collection.sections) &&
            collection.sections.map((section) => {
              const articlesOfSection = Array.isArray(section.articles)
                ? section.articles
                  .filter(({ file }) => file)
                  .map(({ file }) => file.childMarkdownRemark)
                : []

              // skip sections without articles
              if (articlesOfSection.length === 0) return null

              return (
                <React.Fragment key={section.id}>
                  {/* Id must be set for navigation */}
                  <a
                    id={slug(section.id)}
                    href={"#" + slug(section.id)}
                    sx={{
                      cursor: "default",
                      ":hover": { textDecoration: "none" },
                    }}
                  >
                    <h3 className="text-gray-800 text-lg mt-8 mb-3">
                      {section.title}
                    </h3>
                  </a>
                  <ul sx={{ ml: 0, listStyleType: "none" }}>
                    {articlesOfSection.map((article, index) =>
                      article ? (
                        <li key={article.fields.slug} className="mb-0">
                          <Card
                            to={article.fields.slug}
                            title={article.frontmatter.title}
                            description={article.frontmatter.description}
                            hasPredecessor={index > 0}
                            hasSuccessor={index < articlesOfSection.length - 1}
                          />
                        </li>
                      ) : null
                    )}
                  </ul>
                </React.Fragment>
              )
            })}
        </article>
      </Layout>
    )
  }
}

export default CollectionTemplate

export const pageQuery = graphql`
  fragment CollectionArticleFragment on File {
    childMarkdownRemark {
      excerpt
      fields {
        slug
      }
      parent {
        id
        ... on File {
          name
          sourceInstanceName
        }
      }
      frontmatter {
        title
        description
      }
    }
  }

  query CollectionArticlesByCollectionId($collectionId: String!) {
    site {
      siteMetadata {
        title
        texts {
          allCollectionsText
          articlesInCollectionZeroText
          articlesInCollectionOneText
          articlesInCollectionTwoText
          articlesInCollectionMultipleText
        }
      }
    }
    collection: collectionsYaml(id: { eq: $collectionId }) {
      id
      title
      description
      icon
      articles {
        file {
          ...CollectionArticleFragment
        }
      }
      sections {
        id
        title
        articles {
          file {
            ...CollectionArticleFragment
          }
        }
      }
      fields {
        slug
      }
    }
  }
`
